import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Aqui estão algumas perguntas recebidas frequentemente. Se você tiver outras dúvidas, sinta-se à vontade para entrar em contato através dos nossos canais informados no final do site.",
  imageSrc = "",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Como funciona a consulta com o fonoaudiólogo?",
      answer:
        `O fonoaudiólogo estrutura as consultas em três fases: anamnese (entrevista), grupo de avaliação, e tratamento. 
        É realizada uma entrevista detalhada para identificar as necessidades e entender as queixas do paciente. 
        Com os dados coletados, é feito uma escolha de protocolos para avaliação, assim que feitas, é definida a linha do 
        tratamento a ser seguido.`
    },
    {
      question: "Como saber se preciso de tratamento com um fonoaudiólogo?",
      answer:
      (<>Se você nota que tem dificuldades em respirar, sugar, mastigar, deglutir (engolir), trocas na fala, 
      problemas na voz (rouquidão, aspereza ou fica sem voz), problemas na fluência (gagueja ou fala rápido demais), 
      atraso de fala ou problemas para ouvir. É possível que se faça necessário o acompanhamento com um fonoaudiólogo.
      <br/><br/>Se você também tem alguma dúvida, atente-se para marcar uma consulta para esclarecimentos.</>)
    },
    {
      question: "O atendimento fonoaudiológico é só para crianças?",
      answer:
      `Não, o fonoaudiólogo é capacitado para avaliar, orientar, habilitar e reabilitar desde recém-nascidos a idosos 
      nas variadas patologias de sua competência, assim como aperfeiçoar e orientar aspectos relacionados à comunicação 
      como um todo.`
    },
    {
      question: "Meu filho tem dois anos e ainda não fala, é normal?",
      answer:
      (<>Não, a linguagem é considerada um conceito extremamente amplo da comunicação e o desenvolvimento da fala se 
      inicia muito cedo, antes mesmo da criança emitir suas primeiras palavras e por volta de 1 ano de idade, a 
      compreensão de palavras deve ter avançado e espera-se o “papa” e “mama”, que enchem os pais de alegria e marcam o 
      que se espera com início do desenvolvimento da fala propriamente dito e por volta por 2 anos de idade a criança 
      tenha em média de 50 a 100 palavras em seu vocabulário e forme frases com duas ou três palavras.
      <br/><br/>
      Contudo, o mais indicado é procurar ajuda de um profissional para avaliar corretamente o caso.
      </>)
    },
    {
      question: "Meu filho troca as letras ao falar, isso é normal?",
      answer:
      (<>Depende, para cada idade existe uma aquisição fonética e fonológica, ou seja, para cada idade, um som de uma 
      letra é aprendido, como por exemplo: Aos 2 anos de idade, a criança aprende e pronuncia o som do fonema /t/ “t” 
      em tatu. Essa aquisição finaliza com 5 anos, entretanto, é possível identificar se já há trocas ou ausência do 
      som esperado antes mesmo dos 5 anos observando a fala dessa criança.<br/>O ideal é levá-lo a um fonoaudiólogo para 
      fazer uma avaliação e, caso necessário, que seja iniciada a intervenção fonoaudiológica antes do processo de 
      alfabetização, para que as trocas não interfiram na escrita.
      </>)
    },
    {
      question: "Como ocorre o atraso de fala?",
      answer:
      `Existe um tempo esperado para tudo. O atraso no desenvolvimento da fala e da linguagem pode ocorrer por vários 
      motivos, por exemplo, por problemas auditivos, neurológicos, genéticos, etc. Pode ocorrer também, da criança 
      não apresentar nenhum problema orgânico que justifique esse atraso e aí devemos pensar nos aspectos ambientais e 
      nos estímulos que são oferecidos à criança.`
    },
    {
      question: "Quais são os sinais de atrasos na fala ou na linguagem?",
      answer:
      (<>Um bebê que não responde aos sons nem balbucia deve ser observado com mais cuidado pelos pais e pelo pediatra. 
      No entanto, muitas vezes, é difícil para os pais saber se seu filho está demorando um pouco mais para atingir 
      um marco na fala ou na linguagem, ou se há um problema.
      <br/>Veja alguns pontos a serem observados de acordo com a faixa etária:
      <ul>
      <li>&rarr;12 meses: não usa gestos, como apontar ou acenar;</li>
      <li>&rarr;18 meses: prefere gestos a vocalizações para se comunicar; tem dificuldade para imitar sons e não entende solicitações verbais simples;</li>
      <li>&rarr;2 anos: imita fala ou ações, mas não junta palavras ou cria frases espontaneamente, fala apenas algumas palavras repetidamente e não usa a linguagem oral para comunicar algo além do que suas necessidades imediatas; não segue instruções simples; tem um tom de voz incomum (som áspero ou nasal).</li>
      <li>&rarr;Outro sinal importante é quando a fala da criança é mais difícil de entender do que o esperado para a idade. É importante que pais e cuidadores saibam que entre os 2 e 3 anos, a maioria das crianças já é capaz de falar e criar frases, expressando seus desejos pela fala.</li>
      <li>&rarr;Aos 4 anos, elas já são capazes de se fazer compreender, mesmo por pessoas que não a conhecem.</li>
      </ul></>)
    },
    {
      question: "O que vem antes de falar?",
      answer:
      (<>Muitas pessoas não sabem, mas para falar, a criança precisa adquirir algumas habilidades.
      Habilidades estas que são pré-requisitos de fala, ou seja, elas antecedem o ato de falar, vamos conhecer quais são?
      <ul>
      <li>&rarr;Contato visual;</li>
      <li>&rarr;Imitação;</li>
      <li>&rarr;Atenção compartilhada;</li>
      <li>&rarr;Intenção comunicativa;</li>
      <li>&rarr;Compreensão auditiva.</li>
      </ul> 
      Atente-se para notar se seu filho(a) não faz alguma dessas habilidades e busco ajuda com um fonoaudiólogo.</>)
    },
    {
      question: "Por que quem tem dificuldade de aprendizagem precisa de um fonoaudiólogo?",
      answer:
      (<>O acompanhamento com fonoaudiólogos não é algo restrito aos primeiros anos de idade da criança. Pode ser que as 
      dificuldades de fala se perpetuem e sejam refletidas em distúrbios de leitura ou escrita, pois tendemos a 
      utilizar a fala como apoio para escrever, isso acontece quando vamos escrever determinada palavra, e ficamos na 
      dúvida de qual letra contém nela e falamos aquela palavra a fim de descobrir qual letra usar, ou quando a leitura 
      é silabada ou a escrita não possui sentido ou ortografia correta.
      <br/>Se você acha que esse é o caso de alguém da sua família, procure um especialista.<br/>O fonoaudiólogo poderá ajudar 
      a identificar outros quadros, como a dislexia, e orientar pais e professores quanto a melhor forma de se comunicar 
      com a criança e auxiliá-la em seu aprendizado!</>)
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="faq">
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
