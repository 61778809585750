import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import StatsIllustrationSrc from "images/stella-sobre.png";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-medium text-sm sm:text-xs lg:text-sm text-secondary-500 tracking-wide`;
const Key = tw.div`font-bold text-primary-700`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

export default ({
  subheading = "Sobre mim...",
  heading = (
    <>
      Stella <span tw="text-primary-500">Monteiro</span>
    </>
  ),
  description = `Tenho como lema de vida: "De coração para coração, faço dos meus dias ofertas de amor" e tive o privilégio de encontrar na fonoaudiologia diversas formas de aplicar isto, agregando na evolução e melhor qualidade de vida para os pacientes.`,
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Graduação",
      value: "Fonoaudióloga formada pela Universidade do Oeste Paulista (UNOESTE)"
    },
    {
      key: "Pós-graduação",
      value: "Pós-graduada em Distúrbios da Fala e Linguagem pela Faculdade Unyleya"
    },
    {
      key: "Pós-graduanda",
      value: "Fonoaudiologia no Transtorno do Espectro Autista pelo Instituto CBI of Miami"
    },
    {
      key: "Certificações",
      value: ""
    },
    {
      key: "Cursos",
      value: ""
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container id="sobre">
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Key>{statistic.key}</Key>
                  {
                    statistic.key === "Certificações" ?
                    <Value>
                      <ul>
                        <li>- PADOVAN</li>
                        <li>- PECS</li>
                        <li>- PROMPT</li>
                      </ul>
                    </Value> :
                    statistic.key === "Cursos" ?
                    <Value>
                      <ul>
                        <li>- ABA e Estratégias naturalistas</li>
                        <li>- Aplicação e interpretação de protocolos em linguagem infantil: Avaliação fonoaudiológica</li>
                        <li>- Apraxia de fala na infância</li>
                        <li>- Autismo de A a Z: Linguagem e comunicação</li>
                        <li>- Bandagem elástica do Método Therapy Taping</li>
                        <li>- Desenvolvimento de linguagem</li>
                        <li>- Ecolalia</li>
                        <li>- Seletividade alimentar em crianças e adolescentes</li>
                        <li>- TDAH diagnóstico e possibilidades</li>
                        <li>- Entre outros</li>
                      </ul>
                    </Value> :
                    <Value>{statistic.value}</Value> 
                  }                 
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
