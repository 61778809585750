import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import { Link, animateScroll as scroll } from "react-scroll";
//import logo from "../../images/logo.svg";
import logo from "../../images/logotipo-site.png";
import FacebookIcon from "../../images/facebook-icon.png";
import LinkedinIcon from "../../images/linkedin-icon2.png";
import InstagramIcon from "../../images/instagram-icon2.png";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const NavLink = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  img {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  const year = new Date().getFullYear();

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Stella Monteiro</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <NavLink href="">
                Home
              </NavLink>
            </Link> 
            <Link
              activeClass="active"
              to="sobre"
              spy={true}
              smooth={true}
              offset={40}
              duration={500}
            >
              <NavLink href="">
                Sobre
              </NavLink>
            </Link>      
            <Link
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}        
            >
              <NavLink href="">
                Áreas de atendimento
              </NavLink>
            </Link>
            <Link
              activeClass="active"
              to="faq"
              spy={true}
              smooth={true}
              offset={40}
              duration={500}
            >
              <NavLink href="">
                Dúvidas Frequentes
              </NavLink>
            </Link>
            <Link
              activeClass="active"
              to="contato"
              spy={true}
              smooth={true}
              offset={70}
              duration={500}
            >
              <NavLink href="">        
                Entre em contato
              </NavLink>        
            </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.facebook.com/fgastellamonteiro">
            <img src={FacebookIcon} />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.linkedin.com/in/stella-monteiro-03b01a141/">
              <img src={LinkedinIcon} />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.instagram.com/fonostellamonteiro/">
              <img src={InstagramIcon} />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {year}, Stella Monteiro ME. Todos os direitos reservado.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
